import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import SeoSectionTitle from '../atoms/SeoSectionTitle'
import { connect } from 'react-redux'

const SeoSection = props => {
  const { lang, translations } = props
  const classes = useStyles()
  return (
    <>
      {lang === 'pl' &&
        <div>
          <SeoSectionTitle title={translations.section_1.title} color='#94d224' />
            <div className={classes.infoBox}>
              <h2> {translations.section_1.title} </h2>
              <ol>
                <li>{translations.section_1.question_1}</li>
                <p>{translations.section_1.answer_1}</p>

                <li>{translations.section_1.question_2}</li>
                <p>{translations.section_1.answer_2}</p>

                <li>{translations.section_1.question_3}</li>
                <p>{translations.section_1.answer_3}</p>

                <li>{translations.section_1.question_4}</li>
                <p>{translations.section_1.answer_4}</p>

                <li>{translations.section_1.question_5}</li>
                <p>{translations.section_1.answer_5}</p>

                <li>{translations.section_1.question_6}</li>
                <p>{translations.section_1.answer_6}</p>
              </ol>

            </div>
        </div>
      }
    </>
  )
}

SeoSection.propTypes = {
  lang: PropTypes.string.isRequired,
  translations: PropTypes.object.isRequired,
}

const useStyles = createUseStyles({
  infoBox: {
    backgroundColor: 'white',
    marginTop: '2em',
    width: '98%',
    padding: '1em',
    marginBottom: '3em',
    border: '2px dashed #94d224',
    fontSize: '18px',
    lineHeight: '25px',
    textAlign: 'justify',
    '& h2': {
      color: '#94d224',
      fontSize: '19px',
      '&::after': {
        content: "''",
        display: 'block',
        width: '40px',
        height: '3px',
        marginTop: '0.5em',
        backgroundColor: '#94d224',
      },
    },
    '& li': {
      fontWeight: 'bold',
      fontStyle: 'italic',
    },
  },
  '@media(max-width: 960px)': {
    infoBox: {
      fontSize: '16px',
    },
  },
})

const mapStateToProps = (state) => {
  return {
    lang: state.translations.language,
    translations: state.translations.translations.offer_page.seo_section,
  }
}

export default connect(mapStateToProps)(SeoSection)
