import React, { useState} from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import SectionTitle from '../atoms/SectionTitle'
import CustomButton from '../atoms/CustomButton'
import { connect } from 'react-redux'
import SingleSuite from './SingleSuite'

const ApartmentsSection = props => {
  const { translations, } = props
  const classes = useStyles()
  const [activeApartment, setActiveApartment] = useState(0)
  return (
    <div>
      <SectionTitle title={translations.apartaments_section_title} subtitle={translations.apartaments_section_sub_title} color='#19a4e5' backgroundColor='#19a4e5' />
      <div className={classes.mobileTabsContainer}>
        <div className={classes.buttonsSection}>
          <CustomButton title={translations.apartment_0.name} setActiveApartment={setActiveApartment} isActive={activeApartment === 0} id={0}/>
            {activeApartment === 0 && <SingleSuite id={activeApartment} key={Math.random()} /> }
          <CustomButton title={translations.apartment_1.name} setActiveApartment={setActiveApartment} isActive={activeApartment === 1} id={1}/>
            {activeApartment === 1 && <SingleSuite id={activeApartment} key={Math.random()} /> }
          <CustomButton title={translations.apartment_2.name} setActiveApartment={setActiveApartment} isActive={activeApartment === 2} id={2}/>
            {activeApartment === 2 && <SingleSuite id={activeApartment} key={Math.random()} /> }
          <CustomButton title={translations.apartment_3.name} setActiveApartment={setActiveApartment} isActive={activeApartment === 3} id={3}/>
            {activeApartment === 3 && <SingleSuite id={activeApartment} key={Math.random()} /> }
          <CustomButton title={translations.apartment_4.name} setActiveApartment={setActiveApartment} isActive={activeApartment === 4} id={4}/>
            {activeApartment === 4 && <SingleSuite id={activeApartment} key={Math.random()} /> }
          <CustomButton title={translations.apartment_5.name} setActiveApartment={setActiveApartment} isActive={activeApartment === 5} id={5}/>
            {activeApartment === 5 && <SingleSuite id={activeApartment} key={Math.random()} /> }
          <CustomButton title={translations.apartment_6.name} setActiveApartment={setActiveApartment} isActive={activeApartment === 6} id={6}/>
            {activeApartment === 6 && <SingleSuite id={activeApartment} key={Math.random()} /> }
          <CustomButton title={translations.apartment_7.name} setActiveApartment={setActiveApartment} isActive={activeApartment === 7} id={7}/>
            {activeApartment === 7 && <SingleSuite id={activeApartment} key={Math.random()} /> }
          <CustomButton title={translations.apartment_8.name} setActiveApartment={setActiveApartment} isActive={activeApartment === 8} id={8}/>
            {activeApartment === 8 && <SingleSuite id={activeApartment} key={Math.random()} /> }
          <CustomButton title={translations.surroundings.name} setActiveApartment={setActiveApartment} isActive={activeApartment === 9} id={9}/>
            {activeApartment === 9 && <SingleSuite id={activeApartment} key={Math.random()} /> }
        </div>
      </div>
      <div className={classes.tabsContainer}>
        <div className={classes.buttonsSection}>
          <CustomButton title={translations.apartment_0.name} setActiveApartment={setActiveApartment} isActive={activeApartment === 0} id={0}/>
          <CustomButton title={translations.apartment_1.name} setActiveApartment={setActiveApartment} isActive={activeApartment === 1} id={1}/>
          <CustomButton title={translations.apartment_2.name} setActiveApartment={setActiveApartment} isActive={activeApartment === 2} id={2}/>
          <CustomButton title={translations.apartment_3.name} setActiveApartment={setActiveApartment} isActive={activeApartment === 3} id={3}/>
          <CustomButton title={translations.apartment_4.name} setActiveApartment={setActiveApartment} isActive={activeApartment === 4} id={4}/>
          <CustomButton title={translations.apartment_5.name} setActiveApartment={setActiveApartment} isActive={activeApartment === 5} id={5}/>
          <CustomButton title={translations.apartment_6.name} setActiveApartment={setActiveApartment} isActive={activeApartment === 6} id={6}/>
          <CustomButton title={translations.apartment_7.name} setActiveApartment={setActiveApartment} isActive={activeApartment === 7} id={7}/>
          <CustomButton title={translations.apartment_8.name} setActiveApartment={setActiveApartment} isActive={activeApartment === 8} id={8}/>
          <CustomButton title={translations.surroundings.name} setActiveApartment={setActiveApartment} isActive={activeApartment === 9} id={9}/>
        </div>
        <SingleSuite id={activeApartment} key={Math.random()} />
      </div>
    </div>
  )
}

ApartmentsSection.propTypes = {
  translations: PropTypes.object.isRequired,
}

const useStyles = createUseStyles({
  mobileTabsContainer: {
    border: '2px solid #4badc9',
    marginTop: '2em',
    backgroundColor: 'white',
    display: 'none',
    '@media (max-width: 960px)': {
      '&': {
        display: 'block',
      },
    },
  },
  tabsContainer: {
    border: '2px solid #4badc9',
    marginTop: '2em',
    backgroundColor: 'white',
    display: 'block',
    '@media (max-width: 960px)': {
      '&': {
        display: 'none',
      },
    },
  },
  buttonsSection: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  button: {
    width: '25%',
    backgroundColor: '#04bafc',
    border: '1px solid #40a8c5',
    height: '50px',
    fontSize: '16px',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
})

const mapStateToProps = state => {
  return {
    translations: state.translations.translations.offer_page,
  }
}

export default connect(mapStateToProps)(ApartmentsSection)
