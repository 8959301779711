import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import ReactImageZoom from 'react-image-zoom'
import img from '../../images/rzut-domku-z-gory.jpeg'
import { connect } from 'react-redux'

const ZoomImgSection = props => {
  const classes = useStyles()
  const { translations, } = props
  const isMobile = (typeof window !== 'undefined' && window.matchMedia('only screen and (max-width: 760px)').matches)

  return (
    <div className={classes.zoomImgSection}>
      <div className={classes.col}>
        <div className={classes.icon}>
          <FontAwesomeIcon icon={faInfoCircle} />
        </div>
        <div className={classes.contentBox}>
          <h3>{translations.zoom_img_desc_1}</h3><br />
          <h3>{translations.zoom_img_desc_2}</h3><br />
          <h3>{translations.zoom_img_desc_3}</h3>
        </div>
      </div>
      <div className={classes.col}>
        <ReactImageZoom width={600} height={ isMobile ? 325 : 450 } zoomWidth={600} img={img} zoomPosition= 'original' />
      </div>
    </div>
  )
}

ZoomImgSection.propTypes = {
  translations: PropTypes.object.isRequired,
}

const useStyles = createUseStyles({
  zoomImgSection: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  col: {
    width: '49%',
    marginTop: '2em',
  },
  contentBox: {
    marginTop: '2em',
    border: '4px dashed #43b3e6',
    boxSizing: 'border-box',
    backgroundColor: '#f5f5f5',
    padding: '1em',
    '& h3': {
      margin: '0em',
      fontSize: '19px',
      lineHeight: '20px',
      color: '#6b7671',
    },
  },
  icon: {
    textAlign: 'center',
    '& svg': {
      color: '#04bafc',
      width: '2.5em !important',
      height: '2.5em',
    },
  },
  '@media (max-width: 960px)': {
    col: {
      width: '100%',
    },
  },
})

const mapStateToProps = (state) => {
  return {
    translations: state.translations.translations.offer_page,
  }
}

export default connect(mapStateToProps)(ZoomImgSection)
