import React from 'react'
import PropTypes from 'prop-types'
import SectionTitle from '../atoms/SectionTitle'
import { createUseStyles } from 'react-jss'
import { connect } from 'react-redux'
import GoogleMap from './GoogleMap'
import DefaultMapMarker from '../atoms/DefaultMapMarker'

const GastronomySection = props => {
  const classes = useStyles()
  const { translations, } = props
  const geoCodes = [
    { lat: 49.8850529, lng: 20.0816788, },
    { lat: 49.8830099, lng: 20.0873597, },
    { lat: 49.8778191, lng: 20.0945597, },
    { lat: 49.9121594, lng: 20.0472492, },
    { lat: 49.934769, lng: 20.2645089, },
    { lat: 49.8772888, lng: 20.0663194, },
    { lat: 49.8768476, lng: 20.0896838, },
    { lat: 49.9120207, lng: 20.0221076, },
    { lat: 49.8797855, lng: 20.0882701, }
  ]
  return (
    <div>
      <SectionTitle title={translations.gastronomy_section_title} subtitle={translations.gastronomy_section_sub_title} color='#94d224' backgroundColor='#94d224'/>
      <div className={classes.container}>
        <div className={classes.gastronomy}>
          <h3>{translations.gastronomy_desc_1} &nbsp;
          </h3>
          <h4>{translations.recomended}</h4>
          <div className={classes.recomended}>
            <div>
              <h4>{translations.restaurants}</h4>
              <a href="https://www.dworsierakow.pl/pl/restauracja.html" rel="noreferrer" target="_blank">{translations.restaurant_1}</a>
              <a href="http://www.podzamcze-dobczyce.pl/" rel="noreferrer" target="_blank">{translations.restaurant_2}</a>
              <a href="http://hoteldobczyce.pl/" rel="noreferrer" target="_blank">{translations.restaurant_3}</a>
              <a href="https://www.hotelkasztelan.pl/" rel="noreferrer" target="_blank">{translations.restaurant_4}</a>
              <a href="https://pl-pl.facebook.com/pages/category/Pizza-Place/Ostaria239-Restauracja-i-Pizzeria-W%C5%82oska-423344301048443/" rel="noreferrer" target="_blank">{translations.restaurant_5}</a>
              <a href="https://pl-pl.facebook.com/RucolaCaffe" rel="noreferrer" target="_blank">{translations.restaurant_6}</a>
              <a href="https://pl-pl.facebook.com/pages/category/Restaurant/Zajazd-Na-G%C3%B3rkach-436987536458583/" rel="noreferrer" target="_blank">{translations.restaurant_7}</a>
              <a href="https://pl-pl.facebook.com/Restauracja-Carmen-162487073896904/posts/" rel="noreferrer" target="_blank">{translations.restaurant_8}</a>
              <a href="https://www.facebook.com/NienazartyBistro/" rel="noreferrer" target="_blank">{translations.restaurant_9}</a>
              <a href="https://uszwagra24.pl/" rel="noreferrer" target="_blank">{translations.restaurant_10}</a>

            </div>
            <div>
              <h4>{translations.pizzerias}</h4>
              <a href="https://pl-pl.facebook.com/pages/category/Pizza-Place/Ostaria239-Restauracja-i-Pizzeria-W%C5%82oska-423344301048443/" rel="noreferrer" target="_blank">{translations.pizzeria_1}</a>
              <a href="https://pl-pl.facebook.com/RucolaCaffe" rel="noreferrer" target="_blank">{translations.pizzeria_2}</a>
              <a href="http://www.pizzeriacamaro.pl/" rel="noreferrer" target="_blank">{translations.pizzeria_3}</a>
              <a href="http://www.pizzapat.pl/" rel="noreferrer" target="_blank">{translations.pizzeria_4}</a>
              <a href="https://pl-pl.facebook.com/pizzeriasalvedobczyce" rel="noreferrer" target="_blank">{translations.pizzeria_5}</a>
            </div>
            <div>
              <h4>{translations.caffes}</h4>
              <a href="https://pl-pl.facebook.com/LawendaKawiarnia" rel="noreferrer" target="_blank">{translations.cafe_1}</a>
              <a href="https://www.facebook.com/slodkarozpusta1/" rel="noreferrer" target="_blank">{translations.cafe_2}</a>
              <a href="https://pl-pl.facebook.com/RucolaCaffe" rel="noreferrer" target="_blank">{translations.cafe_3}</a>
              <a href="https://www.whitemad.pl/lody-dudzik/" rel="noreferrer" target="_blank">{translations.cafe_4}</a>
            </div>

          </div>

        </div>
        <div className={classes.mapContainer}>
          <GoogleMap
            mapCenter={{ lat: 49.881009, lng: 20.085285, }}
            zoom={14}
            customStyles={{ height: '96%', marginBottom: '0em', }}

          >
          {geoCodes.map(place => (
            <DefaultMapMarker
              key={place.lat}
              {...place}
            />
          ))}

          </GoogleMap>
        </div>
      </div>
    </div>
  )
}

GastronomySection.propTypes = {
  translations: PropTypes.object.isRequired,
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  mapContainer: {
    width: '48%',
  },
  recomended: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    '& div': {
      width: '32%',
    },
  },
  gastronomy: {
    width: '50%',
    border: '2px dashed #78a522',
    backgroundColor: 'white',
    boxSizing: 'border-box',
    marginTop: '2em',
    padding: '1em',
    '& h3': {
      color: '#6B7671',
      lineHeight: '20px',
      fontsize: '20px',
      fontWeight: '400px',
      '& a': {
        textDecoration: 'none',
        fontWeight: '700',
        color: '#868585',
      },
    },
    '& h4': {
      textAlign: 'center',
      fontsize: '15px',
      fontWeight: '500',
      lineHeight: '0px',
      marginBottom: '17px',
    },
    '& a': {
      textDecoration: 'none',
      fontWeight: '700',
      color: '#868585',
      display: 'block',
      textAlign: 'center',
      marginTop: '0.8em',
    },
  },
  '@media (max-width: 960px)': {
    gastronomy: {
      width: '100%',
    },
    mapContainer: {
      width: '100%',
      height: '50vh',
    },
  },
  '@media (max-width: 400px)': {
    recomended: {
      '& div': {
        width: '50%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '1.5em',
      },
    },
  },
})

const mapStateToProps = (state) => {
  return {
    translations: state.translations.translations.offer_page,
  }
}

export default connect(mapStateToProps)(GastronomySection)
