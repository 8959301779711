import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { connect } from 'react-redux'
import SuiteCarousel from '../atoms/SuiteCarousel'

const SingleSuite = props => {
  const classes = useStyles()
  const { id, translations, } = props

  const importAll = (r) => {
    return r.keys().map(r).map(r => r.default)
  }

  const images = {
    0: importAll(require.context('../../images/suite1', false, /\.(png|jpe?g|svg)$/)),
    1: importAll(require.context('../../images/suite2', false, /\.(png|jpe?g|svg)$/)),
    2: importAll(require.context('../../images/suite3', false, /\.(png|jpe?g|svg)$/)),
    3: importAll(require.context('../../images/suite4', false, /\.(png|jpe?g|svg)$/)),
    4: importAll(require.context('../../images/suite5', false, /\.(png|jpe?g|svg)$/)),
    5: importAll(require.context('../../images/suite6', false, /\.(png|jpe?g|svg)$/)),
    6: importAll(require.context('../../images/suite7', false, /\.(png|jpe?g|svg)$/)),
    7: importAll(require.context('../../images/suite8', false, /\.(png|jpe?g|svg)$/)),
    8: importAll(require.context('../../images/suite9', false, /\.(png|jpe?g|svg)$/)),
    9: importAll(require.context('../../images/area', false, /\.(png|jpe?g|svg)$/)),
  }

  const suiteProperty = translations[`apartment_${id}`] ? translations[`apartment_${id}`].properties : null
  return (
    <div className={classes.singleSuite}>
      <div className={classes.suiteDescription}>
        {suiteProperty?
          (
            <>
              <h3>{translations.our_suite}</h3>
              <ol>
                {suiteProperty.map(item => (
                  <li key={item} >{item}</li>
                ))}
              </ol>
            </>
          ):(
          <div className={classes.area}>
            <p>{translations.surroundings.description}</p>
          </div>
        )}
      </div>
      <div className={classes.carousel}>
        <SuiteCarousel images={images[id]} />
      </div>
    </div>
  )
}

SingleSuite.propTypes = {
  translations: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
}

const useStyles = createUseStyles({
  singleSuite: {
    display: 'flex',
    flexWrap: 'wrap',
    animation: '.7s rollout',
    justifyContent: 'space-between',
  },
  suiteDescription: {
    width: '50%',
    padding: '0em 1em',
    '& h3': {
      fontSize: '20px',
      fontWeight: '400',
    },
    '& ol': {
      fontSize: '16px',
      lineHeight: '19px',
    },
  },
  area: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    textAlign: 'center',
    padding: '0em 0.5em',
    fontSize: '20px',
  },
  carousel: {
    width: '50%',
    padding: '0em 2em',
    paddingTop: '1em',
  },
  '@media (max-width: 960px)': {
    suiteDescription: {
      width: '100%',
    },
    carousel: {
      width: '100%',
      padding: '0em',
    },
  },
})

const mapStateToProps = (state) => {
  return {
    translations: state.translations.translations.offer_page,
  }
}

export default connect(mapStateToProps)(SingleSuite)
