import * as React from 'react'
import { connect } from 'react-redux'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { createUseStyles } from 'react-jss'
import PropTypes from 'prop-types'
import LongFacilitiesSection from '../components/molecules/LongFacilitiesSection'
import ApartmentsSection from '../components/molecules/ApartmentsSection'
import GastronomySection from '../components/molecules/GastronomySection'
import SpecialOffersSection from '../components/molecules/SpecialOffersSection'
import ImageCarousel from '../components/atoms/ImageCarousel'
import ZoomImgSection from '../components/molecules/ZoomImgSection'
import OfferSeoSection from '../components/molecules/OfferSeoSection'

const OfferPage = (props) => {
  const { translations, path } = props
  const classes = useStyles()

  return (
  <Layout path={path}>
    <Seo title={translations.page_title} description={translations.page_description} />
    <div className={classes.pageConainer}>
      <div className={classes.imgSection}>
        <div className={classes.roomsOffer}>
          <div className={classes.infoBox}>
            <p> {translations.offer_description} </p>
          </div>
        </div>
        <div className={classes.imgBox}>
          <ImageCarousel alt={translations.main_image_alt} page='offer'/>
        </div>
      </div>

      <LongFacilitiesSection backgroundColor='#94d224' borderColor='#8AB527' />
      <ZoomImgSection />
      <ApartmentsSection />
      <GastronomySection />
      <SpecialOffersSection/>
      <OfferSeoSection />
    </div>
  </Layout>
  )
}

const useStyles = createUseStyles({
  pageConainer: {
    maxWidth: '1200px',
    margin: 'auto',
    padding: '1em',
  },
  imgSection: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginTop: '5em',
  },
  roomsOffer: {
    padding: '1em',
    backgroundColor: 'white',
    width: '47%',
  },
  imgBox: {
    width: '50%',
  },
  infoBox: {
    backgroundColor: 'white',
    padding: '1em',
    border: '2px dashed #94d224',
    '& p': {
      fontSize: '21px',
      color: '#6b7671',
      lineHeight: '32px',
      margin: '0em',
    },
  },
  '@media(max-width: 960px)': {
    infoBox: {
      width: '100%',
      '& p': {
        fontSize: '16px',
      },
    },
    roomsOffer: {
      width: '100%',
      marginBottom: '1em',
    },
    imgBox: {
      width: '100%',
    },
  },

})

OfferPage.propTypes = {
  translations: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => {
  return {
    translations: state.translations.translations.offer_page,
  }
}

export default connect(mapStateToProps)(OfferPage)
