import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import SectionTitle from '../atoms/SectionTitle'
import { connect } from 'react-redux'
import orange from '../../images/orange.png'
import green from '../../images/green.png'
import blue from '../../images/blue.png'

const SpecialOffersSection= props => {
  const classes = useStyles()
  const { translations,links } = props
  return (
    <div>
      <SectionTitle title={translations.special_offers} subtitle={translations.subtitle} color='#ff6f3a' backgroundColor='#ff6f3a' />
      <div className={classes.offerBoxContainer}>
        <div className={classes.threeBoxStyle} >
          <div className={classes.titleBox}>
            <div className={classes.titleTextBox}>
              <p style={{padding: '3%', backgroundColor: '#ff6f3a', width: '100%',}}> {translations.section_one.accomodation_for_empolyees}</p>
            </div>
          </div>
          <div className={classes.textBox}>
            <p>{translations.section_one.sentence_one}
              {translations.section_one.sentence_two}
              {translations.section_one.sentence_three}
            </p>
          </div>

          <div className={classes.spaceBetweenBoxes}>
          </div>

          <a href={links.contact_link}>
            <div className={classes.seeMoreBox}>
              <div style={{ backgroundColor: '#ff6f3a'}} className={classes.seeMoreTex}>
                <button style={{ backgroundColor: '#ff6f3a'}} className={classes.seeMoreButton}>
                  <p style={{ backgroundColor: '#ff6f3a'}}>{translations.learn_more}</p>
                </button>
              </div>
            </div>
            <div className={classes.seeMoreBox}>
              <div className={classes.seeMoreBoxImage}>
                <img
                  src={orange}
                />
              </div>
            </div>
          </a>
        </div>

        <div className={classes.threeBoxStyle} >
          <div className={classes.titleBox}>
            <div className={classes.titleTextBox}>
              <p style={{padding: '3%', backgroundColor: '#94d224',  width: '100%',}}>{translations.section_two.houses_on_green_schools}</p>
            </div>
          </div>
          <div className={classes.textBox}>
            <p>{translations.section_two.sentence_one}
              {translations.section_two.sentence_two}
              {translations.section_two.sentence_three}
            </p>
          </div>

          <div className={classes.spaceBetweenBoxes}>
          </div>

          <a href={links.green_school_link}>
            <div style={{ backgroundColor: '#94d224'}}  className={classes.seeMoreBox}>
              <div style={{ backgroundColor: '#94d224'}} className={classes.seeMoreTex}>
                <button style={{ backgroundColor: '#94d224'}} className={classes.seeMoreButton}>
                  <p style={{ backgroundColor: '#94d224'}}>{translations.learn_more}</p>
                </button>
              </div>
            </div>
            <div className={classes.seeMoreBox}>
              <div className={classes.seeMoreBoxImage}>
                <img
                  src={green}
                />
              </div>
            </div>
          </a>
        </div>

        <div className={classes.threeBoxStyle} >
          <div className={classes.titleBox}>
            <div className={classes.titleTextBox}>
              <p style={{ padding: '3%', backgroundColor: '#04bafc',  width: '100%',}}>{translations.section_three.winter_holidays}</p>
            </div>
          </div>
          <div className={classes.textBox}>
            <p>{translations.section_three.sentence_one}
              {translations.section_three.sentence_two}
              {translations.section_three.sentence_three}
            </p>
          </div>

          <div className={classes.spaceBetweenBoxes}>
          </div>

          <a href={links.winter_holidays_link}>
            <div className={classes.seeMoreBox}>
              <div style={{ backgroundColor: '#04bafc'}} className={classes.seeMoreTex}>
                <button style={{ backgroundColor: '#04bafc'}} className={classes.seeMoreButton}>
                  <p style={{ backgroundColor: '#04bafc'}}>{translations.learn_more}</p>
                </button>
              </div>
            </div>
            <div className={classes.seeMoreBox}>
              <div className={classes.seeMoreBoxImage}>
                <img
                  src={blue}
                />
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  )
}

SpecialOffersSection.propTypes = {
  translations: PropTypes.object.isRequired,
  links: PropTypes.object.isRequired,
}

const useStyles = createUseStyles({
  offerBoxContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: '40px !important',
    marginBottom: '60px !important',
    justifyContent: 'space-between',
    width: '100%',
  },
  threeBoxStyle: {
    margin: '10px 10px 10px 10px',
    padding: '0 10px 10px 10px',
    backgroundColor: '#ffffff',
    cursor: 'pointer',
    width: '30%',
    "&:hover": {
      transition: 'all .5s',
      boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
    },
  },
  titleBox: {
    margin: '-16px -10px 10px -10px',
    color: '#ffffff',
    textAlign: 'center',
  },
  titleTextBox: {
    display: 'flex',
    margin: '0 0 10px 0',
  },
  textBox: {
    minHeight: '150px',
    textAlign: 'justify',
    lineHeight: '130%',
  },
  seeMoreBox: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    float: 'left',
    minWidth: '48%',
    marginBottom: '0.5em',
  },
  seeMoreTex: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  seeMoreButton: {
    color: '#ffffff',
    flexShrink: '1',
    border: 'none',
    '& button': {
      fontSize: '17px',
      '&:hover': {
        boxShadow: '2px 2px 4px 2px rgba( 0, 0, 0, 0.2 )',
      },
    },
  },
  seeMoreBoxImage: {
    flexShrink: '1',
    height: '43px',
    width: '43px',
    backgroundColor: 'transparent!important',
    '&:hover': {
      height: '50px',
      width: '50px',
    },
  },
  spaceBetweenBoxes: {
    margin: '10px 0 10px 0',
    border: '1px solid #f1f1f1',
  },
  '@media(max-width: 650px)': {
    offerBoxContainer: {
      width: '100%',
      '& p': {
        fontSize: '16px',
      },
    },
    threeBoxStyle: {
      width: '100%',
    },
  },
  '@media(max-width: 320px)': {
    threeBoxStyle: {
      width: '100%',
    },
  },
})

const mapStateToProps = state => {
  return {
    translations: state.translations.translations.special_offers_component,
    links: state.translations.translations.navbar.links,
  }
}

export default connect(mapStateToProps)(SpecialOffersSection)
