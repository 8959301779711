import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { connect } from 'react-redux'
import ContainerWithImage from '../atoms/ContainerWithImage'
import childrenImg from '../../images/place-for-children.png'
import disabledImg from '../../images/disabled.png'
import carParkImg from '../../images/car-park.png'
import wiFiImg from '../../images/wi-fi.png'
import grilImg from '../../images/gril.png'
import dogImg from '../../images/dog.png'
import tvImg from '../../images/tv.png'
import tennisImg from '../../images/tennis-raket.png'
import pavillionImg from '../../images/pavillion.png'
import saunaImg from '../../images/sauna.png'
import summerHouseImg from '../../images/summer-house.png'
import showerImg from '../../images/shower.png'
import SectionTitle from '../atoms/SectionTitle'

const LongFacilitiesSection = props => {
  const classes = useStyles()
  const { translations, } = props
  return (
    <>
      <SectionTitle title={translations.facilities} subtitle={translations.we_assure_facilitiess} color='#94d224' backgroundColor='#94d224'/>
      <div className={classes.sectionContainer}>
        <div className={classes.facilitiesSection}>
          <ContainerWithImage
            img={grilImg}
            title={translations.gril}
            description={translations.gril_desc}
            styles={{ width: '49%', backgroundColor:'rgba(138, 191, 39, .8)', borderColor: '#8AB527'}}
          />
          <ContainerWithImage
            img={disabledImg}
            title={translations.diabled}
            description={translations.disabled_desc}
            styles={{ width: '49%', backgroundColor:'rgba(138, 191, 39, .8)', borderColor: '#8AB527' }}
          />
          <ContainerWithImage
            img={showerImg}
            title={translations.shower_and_toilet}
            description={translations.shower_and_toilet_desc}
            styles={{ width: '49%', backgroundColor:'rgba(138, 191, 39, .8)', borderColor: '#8AB527' }}
          />
          <ContainerWithImage
            img={childrenImg}
            title={translations.friendly_place_for_children}
            description={translations.friendly_place_for_children_desc}
            styles={{ width: '49%', backgroundColor:'rgba(138, 191, 39, .8)', borderColor: '#8AB527' }}
          />
          <ContainerWithImage
            img={summerHouseImg}
            title={translations.summer_house}
            description={translations.summer_house_desc}
            styles={{ width: '49%', backgroundColor:'rgba(138, 191, 39, .8)', borderColor: '#8AB527'}}
          />
          <ContainerWithImage
            img={dogImg}
            title={translations.dog_facilities}
            description={translations.dog_facilities_desc}
            styles={{ width: '49%', backgroundColor:'rgba(138, 191, 39, .8)', borderColor: '#8AB527'}}
          />
          <ContainerWithImage
            img={tvImg}
            title={translations.tv_facilities}
            description={translations.tv_facilities_desc}
            styles={{ width: '49%', backgroundColor:'rgba(138, 191, 39, .8)', borderColor: '#8AB527' }}
          />
          <ContainerWithImage
            img={carParkImg}
            title={translations.free_car_park}
            description={translations.free_car_park_desc}
            styles={{ width: '49%', backgroundColor:'rgba(138, 191, 39, .8)', borderColor: '#8AB527'}}
          />
          <ContainerWithImage
            img={saunaImg}
            title={translations.sauna_facilities}
            description={translations.sauna_facilities_desc}
            styles={{ width: '49%', backgroundColor:'rgba(138, 191, 39, .8)', borderColor: '#8AB527'}}
          />
          <ContainerWithImage
            img={tennisImg}
            title={translations.tennis_facilities}
            description={translations.tennis_facilities_desc}
            styles={{ width: '49%', backgroundColor:'rgba(138, 191, 39, .8)', borderColor: '#8AB527'}}
          />
          <ContainerWithImage
            img={wiFiImg}
            title={translations.wi_fi}
            description={translations.wi_fi_desc}
            styles={{ width: '49%', backgroundColor:'rgba(138, 191, 39, .8)', borderColor: '#8AB527' }}
          />
        </div>
      </div>
    </>
  )
}

LongFacilitiesSection.propTypes = {
  translations: PropTypes.object.isRequired,
}

const useStyles = createUseStyles({
  facilitiesSection: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '3em',
    justifyContent: 'space-between',
  },
  sectionContainer: {
    maxWidth: '950px',
    margin: 'auto',
  },
})

const mapStateToProps = (state) => {
  return {
    translations: state.translations.translations.home
  }
}

export default connect(mapStateToProps)(LongFacilitiesSection)
