import React from 'react'
import { CarouselProvider, Slider, Slide, Dot } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import { createUseStyles } from 'react-jss'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const SuiteCarousel = (props) => {
  const { images, translations, } = props
  const classes = useStyles()
  return (
    <CarouselProvider
      naturalSlideWidth={200}
      naturalSlideHeight={200}
      totalSlides={images.length}
      isPlaying
      style={{ position: 'relative', height: '345px', }}
    >
      <Slider >
        {images.map((image, index) => (
          <Slide key={image} index={index}>
            <div className={classes.slide} >
              <img
                className={classes.img}
                src={image}
                alt={`${translations.carousel_img_alt} ${index + 1}`}
              />
            </div>
          </Slide>
        ))}
      </Slider>
      <div className={classes.dotContainer}>
        {images.map((image, index) => (
          <Dot key={image} slide={index} className={classes.dot} />
        ))}
      </div>
    </CarouselProvider>
  )
}

SuiteCarousel.propTypes = {
  translations: PropTypes.object.isRequired,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
}

const useStyles = createUseStyles({
  slide: {
    height: '300px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    position: 'relative',
    width: '100%',

    color: 'white',
    '& h3': {
      top: '50px',
      textShadow: '1px 1px rgba(0,0,0, 0.7)',
      fontSize: '23px',
      margin: '0em 2em',
      paddingTop: '2em',
    },
  },
  title: {
    fontSize: '60px',
    textShadow: '3px 3px rgba(0,0,0, 0.7)',
    animationName: 'example',
    animationDuration: '1s',
    margin: '0em 0.6em',

  },
  img: {
    height: '300px',
    objectFit: 'cover',
    width: '85%',
    float: 'right',
  },
  dot: {
    height: '15px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    marginLeft: '1em',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  dotContainer: {
    textAlign: 'center',
    width: '100%',
    position: 'absolute',
    bottom: '18px',
    right: '-26px',
  },
  '@media (max-width: 960px)': {
    slide: {
      display: 'flex',
      justifyContent: 'center',
    },
    dotContainer: {
      right: '20px',
    },
  },
})

const mapStateToProps = (state) => {
  return {
    translations: state.translations.translations.offer_page,
  }
}

export default connect(mapStateToProps)(SuiteCarousel)
