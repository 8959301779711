import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

const CustomButton = props => {
  const { title, isActive, setActiveApartment, id,  } = props
  const classes = useStyles({ isActive, })
  return (
    <div
      className={classes.button}
      onClick={ () => setActiveApartment(id) }
    >
      {title}
    </div>
  )
}

CustomButton.propTypes = {
  title: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  setActiveApartment: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
}

const useStyles = createUseStyles({
  button: props => ({
    width: '20%',
    backgroundColor: props.isActive ? 'white' : '#04bafc',
    border: '1px solid #40a8c5',
    height: '50px',
    fontSize: '16px',
    color: props.isActive ? 'black' : 'white',
    display: 'flex',
    zIndex: '55',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'initial',
      color: 'black',
    },
  }),
  '@media (max-width: 960px)': {
    button: {
      width: '100% !important',
    },
  },
})

export default CustomButton
